import { createContext, useReducer, useEffect } from "react";

const initialState = {
  currentUser: JSON.parse(localStorage.getItem("user")) || null,
  role: JSON.parse(localStorage.getItem("role")) || null,
};

// Reducer
function AuthReducer(state, action) {
  switch (action.type) {
    case "LOGIN": {
      return {
        currentUser: action.payload,
        role: 'admin', // Set role to 'admin' when logging in
      };
    }
    case "LOGOUT": {
      localStorage.removeItem("user");
      localStorage.removeItem("role");
      return {
        currentUser: null,
        role: '', // Set role to empty string when logging out
      };
    }
    case "LOGIN_ROLE": {
      return {
        role: 'admin',
      };
    }
    case "LOGOUT_ROLE": {
      return {
        role: '',
      };
    }
    default: {
      return state;
    }
  }
}

export const AuthContext = createContext(initialState);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatchAuth] = useReducer(AuthReducer, initialState);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.currentUser));
    localStorage.setItem("role", JSON.stringify(state.role));
  }, [state.currentUser, state.role]);

  return (
    <AuthContext.Provider value={{ currentUser: state.currentUser, role: state.role, dispatchAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
