import React from 'react';
import {Link} from "react-router-dom";
import CalltoActionSeven from "../../elements/calltoaction/CalltoActionSeven";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import NewsletterOne from "./NewsletterOne";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";

const footerIntem =  footerOne[0];
const footerIntemOne =  footerOne[1];
const footerIntemTwo =  footerOne[2];
const footerIntemThree =  footerOne[3];
const footerIntemFour =  footerOne[4];
const footerIntemFive =  footerOne[5];

const indexOneLink = (footerIntemOne.quicklink);
const indexTwoLink = (footerIntemTwo.quicklink);
const indexThreeLink = (footerIntemThree.quicklink);

const FooterFour = () => {
    return (
        <>
            <footer className="rn-footer footer-style-default no-border">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="rn-footer-widget">
                            <div className="logo xxxweee">
                                <Logo 
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo-white.png`}
                                    image2={`${process.env.PUBLIC_URL}/images/logo/logo-white.png`}
                                />
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
                <NewsletterOne newsletetrStyle="rn-newsletter-default" extraClass="border-top-bottom" />
                <div className="copyright-area copyright-style-one no-border">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12 rart">
                                <div className="copyright-left">
                                    <ul className="ft-menu link-hover" >
                                        <li><a  style={{color:"white"}} href="#">Privacy Policy</a></li>
                                        <li><a style={{color:"white"}} href="#">Terms And Condition</a></li>
                                        <li><a style={{color:"white"}} href="/iletisim">Contact Us</a></li>
                                    </ul>
                                    
                                </div>
                            </div>
                            <div className='col-lg-5 cold-md-6 col-sm-12 col-12 rart'>  
                            <ul className="social-icon social-default">
                            <li><Link to="facebook.com"><FiFacebook /></Link></li>
                            <li><Link to="twitter.com"><FiTwitter /></Link></li>
                            <li><Link to="instagram.com"><FiInstagram /></Link></li>
                            <li><Link to="linkdin.com"><FiLinkedin /></Link></li>
                        </ul></div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12 rart">
                                <div className="copyright-right text-center text-md-end">
                                    <p style={{color:"white"}} className="copyright-text">© Odina Logistics {new Date().getFullYear()}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTop />
        </>
    )
}

export default FooterFour;
