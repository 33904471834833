import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";

// Initialize Firebase
const app = initializeApp(
  {
    apiKey: "AIzaSyDM-32wYl43eIZmHzr9Ftux7e-Pvukf66w",
    authDomain: "astralfirebase.firebaseapp.com",
    projectId: "astralfirebase",
    storageBucket: "astralfirebase.appspot.com",
    messagingSenderId: "994101761710",
    appId: "1:994101761710:web:b3787676a62e7862303f3f",
    measurementId: "G-JCS28E4YMC"
  }
)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
export const messaging = getMessaging(app);