import React, { useContext } from "react";
import ContactForm from "./ContactForm";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import LanguageContext from "../../LanguageContext";
import { FaMapLocationDot } from "react-icons/fa6";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { LuMail } from "react-icons/lu";
import { CiLocationOn } from "react-icons/ci";
import { MdMap } from "react-icons/md";
import contactimage from '../../assets/images/bg/Contactt.png'
import FooterFour from "common/footer/FooterFour";
const ContactOne = () => {
  const { translations } = useContext(LanguageContext);

  return (
    <>
      <div className="row contactholder" >
      <div style={{display:"grid",justifyContent:"center",width:"100%"}}>
      <h1 className="innertexth12orc newakefont">{translations.navfive}</h1>
      </div>
        <div className="col-lg-6 col-sm-12" style={{ display: "grid" }}>
          <div className="rn-contact-address mt_dec--50">
           
            <div>
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.7218569233537!2d29.111219985094355!3d41.00946069747933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac8be2912bc07%3A0x280591c7393bc8fa!2sAtay%20Cd.%20%26%20Soyak%20Yeni%C5%9Fehir%20Palmiye%20Evleri%2C%20Site%2C%2034760%20%C3%9Cmraniye%2F%C4%B0stanbul!5e0!3m2!1str!2str!4v1705913866092!5m2!1str!2str"
                  width="100%"
                  height="537px"
                  style={{ border: "0", borderRadius: "0px" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
          <div>
            <div class="contactt-info">
              <div class="contactt-item topcontanctodina">
                <MdMap />
                <p>
                {translations.adres}
                
                </p>
              </div>
              <div class="contactt-item topcontanctodina">
                <TbDeviceLandlinePhone />
                
                <p>{translations.tel}</p>
              </div>
              <div class="contactt-item topcontanctodina">
                <LuMail></LuMail>
                <p>
                  <a href="mailto:operation@odinalogistics.com">
                  {translations.cail}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12" >
          <ContactForm formStyle="contact-form-1" />
        </div>
      </div>    
    </>
  );
};
export default ContactOne;
