import React from 'react';

import image6 from "../../assets/images/bg/middleabout.png";
import { Link } from 'react-router-dom';

const SecondParsh = ({bigtitle,littletitle,inside,btn}) => {

  return (
    <div className="secondparsh ">
      <div className="mainparsh">
        <div className="secondalign ">
          {" "}
          <span className="newakefont secondgridalign mainfonti">
            {bigtitle}
          </span>
        </div>
        <div className="contexmain">
        <div class="contex">
          <div class="text-content">
            <h1 className="newakefont mainfontsubi">
            {littletitle}
            </h1>
            <p className="dewakefont"> {inside}</p>
            <Link style={{width:"100%",textAlign:"center"}} to="/aboutus">
            <button className="custom-button2 newakefont">{btn}</button>
            </Link>
          </div>
          <div class="image-content">
            <img className="bamboimg" src={image6} alt={image6} />
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default SecondParsh;
