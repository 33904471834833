// @mui material components
import Grid from "@mui/material/Grid";

// Admin panel React components
import MDBox from "components/MDBox"
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

// Admin panel React example components
import * as React from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Bill from "layouts/brands/data/brandsDetailCard";
import { useParams } from "react-router-dom"

//firestore
import { db } from "../../../firebase"
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import jsonData from "../../../data/blog/BlogListt.json"

function Detail() {
  const [data, setData] = React.useState({
    id: 0,
    title: "",
    body: "",
    date: "",
    image: "",
    // diğer alanları da ekleyin
  });  const { id } = useParams()

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://mrtportservices.com:5000/getDetail?origin=astwrlwde&id=${id}&additionalParam=haberler`);
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Fetch failed');
        }

          setData(prevState => ({
            ...prevState,
            id: data[0].id,
            title:data[0].title,
            body:data[0].body,
            date:data[0].date,
            image: data[0].image,

          }));
   


      } catch (error) {
      } finally {
      }
    };

    fetchData();
  }, [id]);

  console.log("Outside useEffect - Current data:", data);
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox pt={3} px={2}>
                    <MDTypography variant="h6" fontWeight="medium" sx={{ textAlign: 'center' }}>
                      Haber Detay
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={1} pb={2} px={2}>
                    <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                     {data && <Bill
                        id={data.id}
                        title={data.title}
                        body={[data.body]} 
                        date={data.date}
                        logo={`${process.env.PUBLIC_URL}/${data.image}`}
                      />}
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default Detail;
