import React from 'react';


const SectionTitle = ({subtitle, title, description, textAlign, radiusRounded}) => {
    return (
        <div className={`section-title ${textAlign}`}>
      
        </div>
    )
}
export default SectionTitle;