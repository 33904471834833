import React, { useState, useEffect } from "react";
import BlogListAstral from './BlogListAstral';
import { FaSpinner } from "react-icons/fa";
import filters from "./Filters";


const BlogProp = ({ column, StyleVarProp }) => {
    const [getAllItems, setGetAllItems] = useState([]);
    const [dataVisibleCount, setDataVisibleCount] = useState(6);
    const [dataIncrement] = useState(3);
    const [activeFilter, setActiveFilter] = useState("");
    const [visibleItems, setVisibleItems] = useState([]);
    useEffect(() => {


        fetchData(); // Veriyi ilk yükleme
  
      }, []);
    
      const fetchData = async () => {
        try {
          const response = await fetch("https://mrtportservices.com:5000/getData?blog=blogorigin=astwrlwde"); // API endpointinizi buraya ekleyin
          const result = await response.json();
          const reversedData = result.reverse();

          setVisibleItems(reversedData.slice(0, 3));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      const blogDataa = [
        {
            id: 1,
            title: "Kozmik Keşifler: Uzayın Derinliklerine Yolculuk",
            date: "05 May 2024",
            image: "https://www.yesillojistikciler.com/d/news/21536.jpg",
            body: {
                html: "<p>Uzayın derinliklerinde yapılan yeni keşifler, astronomi dünyasını heyecanlandırıyor. Bu yazıda, son teknoloji teleskoplarla elde edilen bulguları ve evrenin gizemlerini inceliyoruz.</p>"
            }
        },
        {
            id: 2,
            title: "Geleceğin Teknolojisi: Yapay Zeka Uygulamaları",
            date: "05 May 2024",
            image: "https://www.haber1.com/wp-content/uploads/2023/12/dincer-lojistik-2022-surdurulebilirlik-raporunu-yayimladi_ca3a1e3-jpg.webp",
            body: {
                html: "<p>Yapay zeka teknolojisinin gelişimiyle birlikte hayatımızın her alanında önemli değişiklikler meydana geliyor. Bu makalede, yapay zeka uygulamalarının gelecekteki etkilerini ele alıyoruz.</p>"
            }
        },
        {
            id: 3,
            title: "Sürdürülebilir Gelecek: Yeşil Enerji Çözümleri",
            date: "05 May 2024",
            image: "https://www.freightwaves.com/wp-content/uploads/2022/07/shutterstock_2169006455-e1658929345847-1200x674.jpg",
            body: {
                html: "<p>İklim değişikliği ile mücadelede yeşil enerji çözümleri ön plana çıkıyor. Bu yazımızda, sürdürülebilir enerji kaynakları ve çevreye duyarlı teknolojiler hakkında bilgi veriyoruz.</p>"
            }
        }
    ];

    return (
        <>
            <div className="col-lg-8" >
                <div className="row row--15">
                    {blogDataa.map((item) => (
                        <div key={item.id} className={column}>
                            <BlogListAstral StyleVar={StyleVarProp} data={item} />
                        </div>
                    ))}
                </div>
            </div>
           
        </>
    )
}

export default BlogProp;
