import React, { useEffect,useContext } from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterThree from '../common/footer/FooterThree';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import AboutThree from '../elements/about/AboutThree';
import ServiceFive from "../elements/service/ServiceFive";
import Elements from 'elements/gallery/Gallery';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import FooterFour from 'common/footer/FooterFour';
import LanguageContext from '../LanguageContext';
import about from "../assets/images/bg/about2.png"
import about2 from "../assets/images/bg/about2.png"


function AboutUs  ()  {
  const { translations } = useContext(LanguageContext);


    return (
        <>
            <SEO title="Hakkımızda" />
            <main className="page-wrapper" style={{width:"100%"}}>
               
  <div>
  <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
</div>
<div class="containerrrr">
  <div class="row">
  <div style={{display:"grid",justifyContent:"center",width:"100%"}}>
  <h1 className="innertexth12orc newakefont">{translations.navtwo}</h1>
  </div>
  <div className='aboutusholder'>
  <div className="col-lg-6" style={{ display: "grid" }}>
  <img src={about2} className='aboutusimageholder' ></img>
  </div>
  <div className="col-lg-6" style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
  <span className='newakefont altmeto2' >{translations.aboutuspagewrite}</span>
  </div>

  </div>
    

</div>
</div>

            </main>
        </>
    )
}

export default AboutUs;
