import React, { useContext } from 'react';
import image6 from "../../assets/images/bg/middleabout.png";
import { Link } from 'react-router-dom';

import LanguageContext from '../../LanguageContext';
const FourParsh = () => {
  const { translations } = useContext(LanguageContext);

  return (
    <div className="fourparsh">

        <h1 className="newakefont innertexth1for">
        {translations.bestofferlinetitle}
            </h1>
            <Link style={{width:"100%",textAlign:"center"}} to="/contact">
      <button className="custom-button3 newakefont">{translations.bestofferlinebtn}</button>
    </Link>
    </div>
  );
};

export default FourParsh;
