import React , {useState,useContext} from 'react';
import emailjs from 'emailjs-com';
import LanguageContext from '../../LanguageContext.js';


const Result = () => {
    return (
        <p className="success-message">Mesajınız gönderildi, yakın zamanda sizinle iletişime geçeceğiz</p>
    )
}

const Notification = ({ color, message }) => {
  let boxShadowColor;
  if (color === "green") {
    boxShadowColor = "rgba(0, 128, 0, 0.7)";
  } else {
    boxShadowColor = "rgba(255, 0, 0, 0.7)";
  }

  const boxStyle = {
    background: color,
    boxShadow: `0 0 10px ${boxShadowColor}`,
  };

  return (
    <div style={boxStyle} className="notnot">
      {message}
    </div>
  );
};

function ContactForm({props , formStyle}) {
  const { translations } = useContext(LanguageContext);

  const sendEmail = async (event) => {
    event.preventDefault();
  
    const emailMessage = `
    İsim: ${bombay},
    Email: ${mail},
    Telefon Numarası: ${tel},
    Mesaj: ${mrsage}
  `;
    const emailData = {
      to: 'operation@odinalogistics.com', // Alıcı adresi
      subject: desc, // Konu, 'desc' state değişkeni olarak alındı
      text: emailMessage // Mesaj içeriği, 'message' state değişkeni olarak alındı
    };
  
    try {
      const response = await fetch('https://mrtportservices.com:5000/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const responseData = await response.json();
      console.log(responseData);
      setGreenNotification(true);
      setTimeout(() => setGreenNotification(false), 5000); // 5 saniye sonra bildirimi kapat
    } catch (error) {
      console.error(error);
      setRedNotification(true);
      setTimeout(() => setRedNotification(false), 5000); // 5 saniye sonra bildirimi kapat
    }
  };
  const [greenNotification, setGreenNotification] = useState(false);
  const [redNotification, setRedNotification] = useState(false);

  const showGreenNotification = () => {
    setGreenNotification(true);
    setTimeout(() => setGreenNotification(false), 3000); // 3 saniye sonra gizle
  };

  const showRedNotification = () => {
    setRedNotification(true);
    setTimeout(() => setRedNotification(false), 3000); // 3 saniye sonra gizle
  };
  

    const [bombay, setBombay] = useState('')
    const [desc, SetDesc] = useState('');  
    const [tel, SetTel] = useState(''); 
    const [mail, SetMail] = useState(''); 
    const [mrsage, SetMrsage] = useState(''); 
    const [errorMessage, SetErrorMessage] = useState(''); 


    const [ result,showresult ] = useState(false);

    const maxWords = 600;
    const maxWordssmall = 100;


    


    const handleBombay= (e) => {
        const text = e.target.value;
        const words = text.trim();
      
        if (words.length <= maxWordssmall) {
          setBombay(text);


        } else {
          alert('100 kelimeyi aşamazsınız.');
          return;
        }
      }
    
      const handleDesc= (e) => {
        const text = e.target.value;
        const words = text.trim();
      
        if (words.length <= maxWordssmall) {
          SetDesc(text);


        } else {
          alert('100 kelimeyi aşamazsınız.');
          return;
        }
      }

      const handleMail= (e) => {
        const text = e.target.value;
        const words = text.trim();
      
        if (words.length <= maxWordssmall) {
          SetMail(text);

        } else {
          alert('100 kelimeyi aşamazsınız.');
          return;
        }
      }

      const handleTel= (e) => {
        const text = e.target.value;
        const words = text.trim();
      
        if (words.length <= maxWordssmall) {
          SetTel(text);

        } else {
          alert('100 kelimeyi aşamazsınız.');
          return;
        }
      }
      const handleMessage = (e) => {
        const text = e.target.value;
        const words = text.trim();
      
        if (words.length <= maxWords) {
          SetMrsage(text);
        } else {
          alert('600 kelimeyi aşamazsınız.');
          return;
        }
      }

    const sendData = (e) => {
      e.preventDefault();
        const bugun = new Date();
    const gun = bugun.getDate();
    const turkceAylar = [
      'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
      'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
    ];
    const ay = turkceAylar[bugun.getMonth()];
    const yil = bugun.getFullYear();
    const birlesik=gun+" "+ay+" "+yil;
    const saat = String(bugun.getHours()).padStart(2, '0');
    const dakika = String(bugun.getMinutes()).padStart(2, '0');
      const saniye = String(bugun.getSeconds()).padStart(2, '0');
        const birlesiksaat=saat+":"+dakika+":"+saniye;
const dayomount = String(bugun.getMonth()+1).padStart(2, '0');
const dayogun = String(bugun.getDate()).padStart(2, '0');
const tarihSaat = `${yil}-${dayomount}-${dayogun}T${saat}:${dakika}:${saniye}`;

    
        const yeniVeri = {
            isim: bombay, 
            email:  mail, 
            telno: tel, 
            konu: desc,
            message: mrsage,
            date:birlesik,
            saat: birlesiksaat,
            backhour:tarihSaat,
            ip:'',
         
          };

          AddMessage(yeniVeri);
    };
    const AddMessage = (degerdata) => {
        fetch('https://mrtportservices.com:5000/addData?origin=astwrlwde', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              degerdata: degerdata,
              blog: 'customermessage',
              origin:'astwrlwde'
            })
          })
            .then((addDataResponse) => {
              if (addDataResponse.status === 200) {
                showGreenNotification();

                
              } else if((addDataResponse.status === 400))
              {
                showRedNotification();
                 SetErrorMessage("2 Dakikada bir mesaj gönderebilirsiniz."); 

              }
               else {
                showRedNotification();
                SetErrorMessage("Hata gönderilmedi"); 

              }
            })
            .catch((error) => {
              showRedNotification();
              SetErrorMessage("Hata gönderilmedi"); 


    
            });
      
    }

   

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
        {greenNotification && (
          <Notification color="green" message="Mesajınız gönderildi ✔" />
        )}
        {redNotification && (
          <Notification color="red" message= "Hata Gönderilemedi"/>
        )}

            <div className="form-group sewakefont">
            <input
            type="text"
            name="user_name"
            placeholder={translations.contactformnm}
            required
            value={bombay} 
            onChange={handleBombay} 
          />
            </div>
            <div className="form-group sewakefont">
                <input 
                type="email"
                name="user_email"
                placeholder={translations.contactformem}
                required
                value={mail} 
                onChange={handleMail} 
                />
            </div>

            <div className="form-group sewakefont">
                <input 
                type="number"
                name="user_tel"
                placeholder={translations.contactformph}
                required
                value={tel} 
                onChange={handleTel} 
                />
            </div>


            <div className="form-group sewakefont">
                <input 
                type="text"
                name="user_konu"
                placeholder={translations.contactformcontit}
                required
                value={desc} 
                onChange={handleDesc} 
                />
            </div>

            <div className="form-group sewakefont">
                <textarea 
                name="message"
                placeholder={translations.contactformcont}
                required
                value={mrsage} 
                onChange={handleMessage} 
                >
                </textarea>
            </div>

            <div className="form-group">
            
            <button class="button" type="submit">
            <span><p style={{lineHeight:"55px",fontSize:"18px"}}>{translations.TACOFORM}</p></span>
	<div class="button__horizontal"></div>
	<div class="button__vertical"></div>
</button>
            </div> 

            <div className="form-group">
                {result ? <Result /> : null}
            </div>
    
        </form>
        
    )
}
export default ContactForm;
