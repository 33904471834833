import React, { useEffect, useState,useRef,useContext } from 'react';
import { Link } from 'react-router-dom';
import SEO from '../common/SEO';
import HeaderTwo from '../common/header/HeaderTwo';
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';

import CenterHeader from '../common/header/CenteredHeader';



import 'react-tooltip/dist/react-tooltip.css';



import LanguageContext from "../LanguageContext";


import FooterFour from '../common/footer/FooterFour';
import FooterTwo from '../common/footer/FooterTwo';
import SliderComponent from '../elements/slider/slider'
import SecondParsh from '../elements/slider/secondparsh'
import ThirdParsh from 'elements/slider/thirdparsh';
import FourParsh from 'elements/slider/fourparsch';


function Finance() {
  

  const { translations } = useContext(LanguageContext);


  return (
    <>
      <SEO title="Anasayfa" />
      <main className="page-wrapper">
        <div>

          <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="rn-header header-default header-not-transparent header-default " Loco="main"/>
          <SliderComponent bigtitle={translations.firstybigtitle} metin={translations.firstybigdesc} buttor={translations.firstybigbutton}></SliderComponent>
          <SecondParsh bigtitle= {translations.bigmainaboutus} littletitle= {translations.mainaboutus} inside={translations.aboutuspagewrite} btn={translations.mainaboutusbutton}></SecondParsh>
          <FourParsh></FourParsh>
          <ThirdParsh latestnews={translations.latestnewws}></ThirdParsh>
          <FooterFour></FooterFour>
          

        </div>

      </main>
    </>
  );
}

export default Finance;

