import {useState, useRef,useContext} from "react";
import { FiMenu } from "react-icons/fi";
import { FiArrowRight  } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import Darkmode from "./Darkmode";
import useStickyHeader from "./useStickyHeader";
import { useNavigate } from 'react-router-dom';
import LanguageContext from '../../LanguageContext.js';
import { IoIosArrowDown } from "react-icons/io";
import { Link } from 'react-router-dom';



const HeaderOne = ({btnStyle, HeaderSTyle,Loco}) => {
    const { translations } = useContext(LanguageContext);

    const navigate = useNavigate();

    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow(prev => !prev);
    }
    const ref = useRef();
    let [check, setCheck] = useState(true);
    const sticky = useStickyHeader( 50 );
    const headerClasses = `header-default ${(sticky && check) ? 'sticky' : ''}`
    const { clientHeight } = ref;
        /* <Darkmode />*/

    const checkChange = (value) => {
      setCheck(value);
    };
    const scrollDown = () => {
        if(Loco==="main"){
            window.scrollTo({ top: 1400, left: 0, behavior: 'smooth' });
        }
        else{
            navigate('/getoffer'); // '/getoffer' sayfasına yönlendir

        }
      };
    return (
        <>
            <header style={{zIndex:"994"}} ref={ref} className={`rn-header header-default ${HeaderSTyle} ` }>
                <div className="headerpad">
                        
                    <div className="row align-items-center row--0">
                        <div  className="col-lg-4 col-md-6 col-9 col-sm-9">
                <Logo 
                                image={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                                image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                            />

                        </div>
                        <div className="col-lg-6 col-md-6 col-3 col-sm-3 position-static dixad padi rawraw">
                            <div className="header-right">
                                <nav className="mainmenu-nav d-none d-lg-block">
                                    <Nav />
                                </nav>
                          
                                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                    <div  style={{marginRight:"10px"}} className="hamberger">
                                        <span className="hamberger-button" onClick={onCanvasHandler}><FiMenu /></span>
                                    </div>
                                </div>
                            </div>  
                        </div>
             
                    </div>
                </div>
             
            </header>
            <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler}  />
        </>
    )
}
export default HeaderOne;