import React, { useContext, useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import LanguageContext from "../../LanguageContext.js";
import LanguageDropdown from "./LanguageSelect.js";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";

const Nav = () => {
  const { language, changeLanguage, translations } = useContext(LanguageContext);


  const handleClick = () => {
    window.location.href = '/getoffer';
  };

  const handleLanguageChange = (event) => {
    changeLanguage(event.target.value);
  };
  return (
    <ul className="mainmenu">
    <li className="has-droupdown newakefont">
      <ul style={{ margin: 0 }} className="navwreck">
        <Link to="/">{translations.navone}</Link>
      </ul>
    </li>
    <li className="has-droupdown newakefont">
    <ul className="navwreck">
      <Link to="/services">{translations.navthree}</Link>
    </ul>
  </li>
    <li className="has-droupdown newakefont">
      <ul className="navwreck">
        <Link to="/aboutus">{translations.navtwo}</Link>
      </ul>
    </li>

    <li className="has-droupdown newakefont">
      <ul className="navwreck">
        <Link to="/contact">{translations.navfive}</Link>
      </ul>
    </li>
    <li className="has-droupdown newakefont">
      <div className="btn-cont" style={{ marginTop: "-5px" }}>
        <LanguageDropdown
          language={language}
          changeLanguage={changeLanguage}
        />
      </div>
    </li>
    <li></li>
 
  </ul>
  );
};

function throttle(fn, wait) {
  let lastTime = 0;
  return function (...args) {
    const now = new Date().getTime();
    if (now - lastTime < wait) return;
    lastTime = now;
    return fn(...args);
  };
}
export default Nav;

