import React, { useState, useEffect } from 'react';
import HeaderOne from 'common/header/HeaderOne';
import Slo from 'elements/slider/slider2';



function Servos() {


  return (
    <>
	  <main className="page-wrapper" style={{width:"100%"}}>
	 <div>
  <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
</div>
<Slo></Slo>

    </main>
  </>
  );
}

export default Servos;
