import React, {useContext } from 'react';

import Slider from "react-slick";
import image1 from '../../assets/images/bg/blueback.png';
import image2 from '../../assets/images/bg/blueback4.png';
import image22 from '../../assets/images/bg/blueback4back.png';

import image3 from '../../assets/images/bg/blueback3.png';
import image33 from '../../assets/images/bg/blueback3back.png';

import image4 from '../../assets/images/bg/blueback5.png';
import image44 from '../../assets/images/bg/blueback5back.png';

import image5 from '../../assets/images/bg/blueback6.png';
import image55 from '../../assets/images/bg/blueback6back.png';
import LanguageContext from "LanguageContext";





const Slo = () => {
  const { translations } = useContext(LanguageContext);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false           // Okları kapatmak için
};
  const slidesData = [
    {
      id: 1,
      title: translations.hizmetair,
      description: translations.hizmetairdesc,
      imageUrl:image3,
      backimageurl:image33,
      objectPosition: 'center',
      additionalClass :'planee',
      additionalClassextra:"",
      additionalClassextra2:"border-radius: 10px; border: 5px solid rgba(255, 255, 255, .8); "


    },
    {
        id: 2,
        title: translations.hizmetsail,
        description:translations.hizmetsaildesc,
        imageUrl: image2,
        backimageurl:image22,
        objectPosition: 'center',
        additionalClass :'ship',
        additionalClassextra:"",
        additionalClassextra2:"border-radius: 10px; border: 5px solid rgba(0, 0, 0, .8); "

        
      },
      {
        id: 3,
        title: translations.hizmetroad,
        description: translations.hizmetroaddesc,
        imageUrl: image4,
        backimageurl:image44,
        objectPosition: 'bottom',
        additionalClass :'truck',
        additionalClassextra:"",
        additionalClassextra2:"border-radius: 10px; border: 5px solid rgba(0, 0, 0, .8); "


      },
      {
        id: 4,
        title: translations.hizmetrail,
        description: translations.hizmetraildesc,
        imageUrl: image5,
        backimageurl:image55,
        objectPosition: 'bottom',
        additionalClass :'train',
        additionalClassextra2:"border-radius: 10px; border: 5px solid rgba(255, 255, 255, .8); "

      },
    // Diğer slaytlarınızı buraya ekleyin
  ];

  return (
    
    <div className="mainoddinaaaaa2">
  

         
      <Slider {...settings}>
  {slidesData.map((slide) => (
    <div key={slide.id} className=".slider-item2 mainpageodinadiv">

    <img  className="overlay-imagewhite" src={slide.backimageurl} alt={slide.backimageurl} />

      <img style={{ objectFit: 'cover', objectPosition: slide.objectPosition}} className="odinaimgdiv2" src={slide.imageUrl} alt={slide.title} />
      <div className={`text-overlayod  ${slide.additionalClass}`}>
              <h2 className="newakefont">{slide.title}</h2>
              <p style={slidesData.additionalClassextra} className="dewakefont">{slide.description}</p>
            </div>
    </div>
   
   
  ))}
</Slider>
    </div>
  );
};

export default Slo;
