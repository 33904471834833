import React from "react";
import Slider from "react-slick";
import image1 from '../../assets/images/bg/blueback.png';
import image2 from '../../assets/images/bg/blueback4.png';
import image3 from '../../assets/images/bg/blueback3.png';
import image4 from '../../assets/images/bg/blueback5.png';
import image5 from '../../assets/images/bg/blueback6.png';
import { Link } from 'react-router-dom';





const SliderComponent = ({bigtitle,metin,buttor}) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    vertical: true,         // Dikey kaydırma için
    verticalSwiping: true,  // Dikey kaydırma hareketleri için
    arrows: false           // Okları kapatmak için
};
  const slidesData = [
    {
      id: 1,
      title: "HAVAYOLU TASIMACILIGI",
      description: "Bu birinci slaytın açıklaması",
      imageUrl:image3,
      objectPosition: 'center',
      buttonLabel: "Daha Fazla"
    },
    {
        id: 2,
        title: "DENIZYOLU TASIMACILIGI",
        description: "Bu birinci slaytın açıklaması",
        imageUrl: image2,
        objectPosition: 'center',
        buttonLabel: "Daha Fazla"
        
      },
      {
        id: 3,
        title: "Slide 1",
        description: "Bu birinci slaytın açıklaması",
        imageUrl: image4,
        objectPosition: 'bottom',
        buttonLabel: "Daha Fazla"
      },
      {
        id: 4,
        title: "Slide 1",
        description: "Bu birinci slaytın açıklaması",
        imageUrl: image5,
        objectPosition: 'bottom',

        buttonLabel: "Daha Fazla"
      },
    // Diğer slaytlarınızı buraya ekleyin
  ];

  return (
    <div className="mainoddinaaaaa" style={{}}>
  
    <div className="vurgun"> 
            <h1 className="davxee dewakefont">{bigtitle}</h1>
            <p className="newakefont altmeto">{metin}</p>
            <Link style={{width:"100%",textAlign:"center"}} to="/services">
            <button className="custom-button newakefont">{buttor}</button>
            </Link>
</div>

       <div style={{marginTop:"60px"}}>
      <Slider {...settings}>
  {slidesData.map((slide) => (
    <div key={slide.id} className=" mainpageodinadiv" >
      <img style={{ objectFit: 'cover'}} className="odinaimgdiv" src={slide.imageUrl} alt={slide.title} />
    </div>
  ))}
</Slider>
</div>  
    </div>
  );
};

export default SliderComponent;
