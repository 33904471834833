import React from 'react';
import {slugify} from "../../../utils";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import parse from 'html-react-parser'; // HTML'i React elemanlarına dönüştürmek için

//<img src={`${process.env.PUBLIC_URL}/${data.image}`} alt="Blog Image" className="innerbreaker" />

const BlogListAstral = ({ data, StyleVar }) => {

    function limitContentByWords(htmlContent, limit) {
        const textContent = htmlContent.replace(/<[^>]+>/g, ''); // HTML etiketlerini kaldır
        const words = textContent.split(' ');
        const limitedWords = words.slice(0, limit);
        return limitedWords.join(' ') + (words.length > limit ? '...' : ''); // Eğer limiti aşıyorsa "..." ekle
    }

    const limitedContent = limitContentByWords(data.body.html, 15); // Burada doğru property'yi kullan
    function turkishToEnglish(text) {
        const turkishChars = "çÇğĞıİöÖşŞüÜ";
        const englishChars = "cCgGiIoOsSuU";
        const charMap = {};
    
        for (let i = 0; i < turkishChars.length; i++) {
            charMap[turkishChars.charAt(i)] = englishChars.charAt(i);
        }
    
        return text.replace(/[çÇğĞıİöÖşŞüÜ]/g, function (matched) {
            return charMap[matched];
        });
    }

    const englishTitle = turkishToEnglish(data.title);

    return (
        <div className={`rnn-card ${StyleVar}`}>
            <div className="inner">
                <div className="thumbnail">
                    <Link to="#">
                    <img src={data.image} alt="Blog Image" className="innerbreaker" />
                    </Link>
                    </div>

                <div className="content">
                    <ul className="rn-meta-list">
                   
                        <li className='sewakefont'>{data.date}</li>
                    </ul>
                    <h4 className="title newakefont">
                        <Link to="#">
                            {englishTitle}
                        </Link>
                    </h4>

                    <span className="dewakefont" style={{marginTop:"10px"}}>
                    <div className="" dangerouslySetInnerHTML={{__html: limitedContent}}></div>

                    </span>
                </div>
            </div>
        </div>
    )
}
BlogListAstral.propTypes = {
    data: PropTypes.object
};
export default BlogListAstral;
