import React from "react";

import image6 from "../../assets/images/bg/middleabout.png";
import BlogProp from "components/blog/itemProp/BlogProp"; 

const ThirdParsh = ({latestnews}) => {

  return (
    <div className="thirdparsh">
    <div>
      <h1 className="newakefont innertexth12or">
              {latestnews}
            </h1>
            </div>
            <div style={{display:"flex",justifyContent:"center"}}>
   <BlogProp column="col-lg-4 col-md-6 col-12 mt--30" StyleVarProp="box-card-style-default" />
   </div>
    </div>
  );
};

export default ThirdParsh;
